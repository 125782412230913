.scannerOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  width: "auto";
  height: "auto";
  position: fixed;
  color: white;
  background: red;
}
