body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.detailLabel {
  font-weight: bold !important;
  text-transform: uppercase;
  color:#707070;
  font-size: 12px !important;
  margin-right: 5px !important;
  vertical-align: middle;
  letter-spacing: 0.5px !important;
}
